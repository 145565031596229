import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import styles from './assets/scss/styles.scss'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.interceptors.request.use((config) => {
	config.baseURL = '/api'
	return config
})

createApp(App).use(store).use(VueAxios, axios).use(router).mount('#app')
