<template>
	<div
		@mousedown="onMousedown($event)"
		@mouseup="onMouseup"
		@mousemove="onMousemove($event)"
		@mouseleave="onMouseup"
		class="noselect"
	>
		<slot />
	</div>
</template>
<script setup>
import { ref } from 'vue'

let clicked = ref(false)
let clickY = ref(false)

const onMousedown = (e) => {
	clicked.value = true
	clickY.value = e.pageY
}

const onMouseup = () => {
	clicked.value = false
}

const onMousemove = (e) => {
	if (clicked.value) {
		// console.log(window.scrollY)
		// $(window).scrollTop($(window).scrollTop() + (clickY - e.pageY));

		window.scrollTo({
			top: window.scrollY + (clickY.value - e.pageY),
			behavior: 'smooth',
		})
	}
}
</script>
