import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/schedule',
		name: 'Schedule',
		component: () => import('../views/Schedule.vue'),
	},
	{
		path: '/documents',
		name: 'Documents',
		component: () => import('../views/Documents.vue'),
	},
	{
		path: '/pdf',
		name: 'PdfViewer',
		component: () => import('../views/PdfViewer.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
